import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _210ac6bf = () => interopDefault(import('../pages/company-partners/index.vue' /* webpackChunkName: "pages/company-partners/index" */))
const _351a7436 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _4d364b30 = () => interopDefault(import('../pages/partner-companies/index.vue' /* webpackChunkName: "pages/partner-companies/index" */))
const _14d0457e = () => interopDefault(import('../pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _bc098e72 = () => interopDefault(import('../pages/admin/affiliations/index.vue' /* webpackChunkName: "pages/admin/affiliations/index" */))
const _5ac338c2 = () => interopDefault(import('../pages/admin/insights/index.vue' /* webpackChunkName: "pages/admin/insights/index" */))
const _46e76d04 = () => interopDefault(import('../pages/admin/projects/index.vue' /* webpackChunkName: "pages/admin/projects/index" */))
const _31609d4c = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _7a766c18 = () => interopDefault(import('../pages/projects/helpers.ts' /* webpackChunkName: "pages/projects/helpers" */))
const _157ce0f1 = () => interopDefault(import('../pages/users/community.vue' /* webpackChunkName: "pages/users/community" */))
const _0b3f4e1c = () => interopDefault(import('../pages/users/dashboard.vue' /* webpackChunkName: "pages/users/dashboard" */))
const _58d64f1e = () => interopDefault(import('../pages/users/login.vue' /* webpackChunkName: "pages/users/login" */))
const _e5429320 = () => interopDefault(import('../pages/users/notifications.vue' /* webpackChunkName: "pages/users/notifications" */))
const _3ced56b1 = () => interopDefault(import('../pages/users/profile.vue' /* webpackChunkName: "pages/users/profile" */))
const _7695cec3 = () => interopDefault(import('../pages/users/request-password-reset.vue' /* webpackChunkName: "pages/users/request-password-reset" */))
const _3a446c4b = () => interopDefault(import('../pages/users/settings.vue' /* webpackChunkName: "pages/users/settings" */))
const _690922bd = () => interopDefault(import('../pages/users/onboarding/interests.vue' /* webpackChunkName: "pages/users/onboarding/interests" */))
const _65a03cfc = () => interopDefault(import('../pages/users/onboarding/job-title.vue' /* webpackChunkName: "pages/users/onboarding/job-title" */))
const _20783eb0 = () => interopDefault(import('../pages/onboarding/partnerships/_slug/index.vue' /* webpackChunkName: "pages/onboarding/partnerships/_slug/index" */))
const _26038383 = () => interopDefault(import('../pages/users/conversations/_id.vue' /* webpackChunkName: "pages/users/conversations/_id" */))
const _e1e64176 = () => interopDefault(import('../pages/users/create-password/_token.vue' /* webpackChunkName: "pages/users/create-password/_token" */))
const _bdde0810 = () => interopDefault(import('../pages/users/reset-password/_token.vue' /* webpackChunkName: "pages/users/reset-password/_token" */))
const _b1e6ff6c = () => interopDefault(import('../pages/admin/affiliations/_slug/edit.vue' /* webpackChunkName: "pages/admin/affiliations/_slug/edit" */))
const _32141c86 = () => interopDefault(import('../pages/admin/affiliations/_slug/projects/index.vue' /* webpackChunkName: "pages/admin/affiliations/_slug/projects/index" */))
const _37ca19c6 = () => interopDefault(import('../pages/admin/affiliations/_slug/team.vue' /* webpackChunkName: "pages/admin/affiliations/_slug/team" */))
const _569b6b97 = () => interopDefault(import('../pages/admin/users/_slug/edit.vue' /* webpackChunkName: "pages/admin/users/_slug/edit" */))
const _006b7787 = () => interopDefault(import('../pages/admin/users/_slug/permissions.vue' /* webpackChunkName: "pages/admin/users/_slug/permissions" */))
const _27ee0963 = () => interopDefault(import('../pages/access/_slug/index.vue' /* webpackChunkName: "pages/access/_slug/index" */))
const _41b676ba = () => interopDefault(import('../pages/company-partners/_slug/index.vue' /* webpackChunkName: "pages/company-partners/_slug/index" */))
const _00322b18 = () => interopDefault(import('../pages/events/_slug/index.vue' /* webpackChunkName: "pages/events/_slug/index" */))
const _702afc60 = () => interopDefault(import('../pages/posts/_id/index.vue' /* webpackChunkName: "pages/posts/_id/index" */))
const _1319a436 = () => interopDefault(import('../pages/projects/_slug.vue' /* webpackChunkName: "pages/projects/_slug" */))
const _6df44515 = () => interopDefault(import('../pages/users/_slug/index.vue' /* webpackChunkName: "pages/users/_slug/index" */))
const _f16a2cca = () => interopDefault(import('../pages/affiliations/_slug/insights.vue' /* webpackChunkName: "pages/affiliations/_slug/insights" */))
const _68b5a039 = () => interopDefault(import('../pages/affiliations/_slug/overview.vue' /* webpackChunkName: "pages/affiliations/_slug/overview" */))
const _18d875bd = () => interopDefault(import('../pages/affiliations/_slug/projects/index.vue' /* webpackChunkName: "pages/affiliations/_slug/projects/index" */))
const _dadb11c6 = () => interopDefault(import('../pages/affiliations/_slug/team.vue' /* webpackChunkName: "pages/affiliations/_slug/team" */))
const _7186fc3e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/company-partners",
    component: _210ac6bf,
    name: "company-partners"
  }, {
    path: "/media",
    component: _351a7436,
    name: "media"
  }, {
    path: "/partner-companies",
    component: _4d364b30,
    name: "partner-companies"
  }, {
    path: "/projects",
    component: _14d0457e,
    name: "projects"
  }, {
    path: "/admin/affiliations",
    component: _bc098e72,
    name: "admin-affiliations"
  }, {
    path: "/admin/insights",
    component: _5ac338c2,
    name: "admin-insights"
  }, {
    path: "/admin/projects",
    component: _46e76d04,
    name: "admin-projects"
  }, {
    path: "/admin/users",
    component: _31609d4c,
    name: "admin-users"
  }, {
    path: "/projects/helpers",
    component: _7a766c18,
    name: "projects-helpers"
  }, {
    path: "/users/community",
    component: _157ce0f1,
    name: "users-community"
  }, {
    path: "/users/dashboard",
    component: _0b3f4e1c,
    name: "users-dashboard"
  }, {
    path: "/users/login",
    component: _58d64f1e,
    name: "users-login"
  }, {
    path: "/users/notifications",
    component: _e5429320,
    name: "users-notifications"
  }, {
    path: "/users/profile",
    component: _3ced56b1,
    name: "users-profile"
  }, {
    path: "/users/request-password-reset",
    component: _7695cec3,
    name: "users-request-password-reset"
  }, {
    path: "/users/settings",
    component: _3a446c4b,
    name: "users-settings"
  }, {
    path: "/users/onboarding/interests",
    component: _690922bd,
    name: "users-onboarding-interests"
  }, {
    path: "/users/onboarding/job-title",
    component: _65a03cfc,
    name: "users-onboarding-job-title"
  }, {
    path: "/onboarding/partnerships/:slug",
    component: _20783eb0,
    name: "onboarding-partnerships-slug"
  }, {
    path: "/users/conversations/:id?",
    component: _26038383,
    name: "users-conversations-id"
  }, {
    path: "/users/create-password/:token?",
    component: _e1e64176,
    name: "users-create-password-token"
  }, {
    path: "/users/reset-password/:token?",
    component: _bdde0810,
    name: "users-reset-password-token"
  }, {
    path: "/admin/affiliations/:slug/edit",
    component: _b1e6ff6c,
    name: "admin-affiliations-slug-edit"
  }, {
    path: "/admin/affiliations/:slug/projects",
    component: _32141c86,
    name: "admin-affiliations-slug-projects"
  }, {
    path: "/admin/affiliations/:slug/team",
    component: _37ca19c6,
    name: "admin-affiliations-slug-team"
  }, {
    path: "/admin/users/:slug/edit",
    component: _569b6b97,
    name: "admin-users-slug-edit"
  }, {
    path: "/admin/users/:slug/permissions",
    component: _006b7787,
    name: "admin-users-slug-permissions"
  }, {
    path: "/access/:slug",
    component: _27ee0963,
    name: "access-slug"
  }, {
    path: "/company-partners/:slug",
    component: _41b676ba,
    name: "company-partners-slug"
  }, {
    path: "/events/:slug",
    component: _00322b18,
    name: "events-slug"
  }, {
    path: "/posts/:id",
    component: _702afc60,
    name: "posts-id"
  }, {
    path: "/projects/:slug",
    component: _1319a436,
    name: "projects-slug"
  }, {
    path: "/users/:slug",
    component: _6df44515,
    name: "users-slug"
  }, {
    path: "/affiliations/:slug?/insights",
    component: _f16a2cca,
    name: "affiliations-slug-insights"
  }, {
    path: "/affiliations/:slug?/overview",
    component: _68b5a039,
    name: "affiliations-slug-overview"
  }, {
    path: "/affiliations/:slug?/projects",
    component: _18d875bd,
    name: "affiliations-slug-projects"
  }, {
    path: "/affiliations/:slug?/team",
    component: _dadb11c6,
    name: "affiliations-slug-team"
  }, {
    path: "/",
    component: _7186fc3e,
    name: "index"
  }, {
    path: "/posts/:id/comment/:slug",
    component: _702afc60,
    name: "Single Post Page Comment"
  }, {
    path: "/affiliations/:affiliationId/projects/:id/edit",
    component: _1319a436,
    props: {"editing":true},
    name: "Edit Project"
  }, {
    path: "/admin/projects/:id/edit",
    component: _1319a436,
    props: {"editing":true},
    name: "Admin Edit Project Temp"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
